<template>

<!-- 用户管理 机构管理页面  新增-->
  <div class="container">
    <div class="body-container">
      <div class="body-section">
        <div class="all-container">
          <div class="all-section">
            <div class="organization-container">
              <div class="organization-header all-section-header">
                <p class="header-title">
                  机构信息
                </p>
              </div>

              <div class="organization-from_section">
                <el-form label-position="right" ref="organizationForm" label-width="120px" :model="basicInfo"
                         :rules="basicInfoRules">
                  <el-form-item label="机构名称" prop="name">
                    <el-input v-model="basicInfo.name"></el-input>
                  </el-form-item>

                  <el-form-item label="电话" prop="phone">
                    <el-input maxlength="11" v-model="basicInfo.phone"></el-input>
                  </el-form-item>
                  <el-form-item label="法人身份证号" prop="legalPersonCard">
                    <el-input v-model="basicInfo.legalPersonCard"></el-input>
                  </el-form-item>

                  <el-form-item label="简介" prop="intro">
                    <el-input v-model="basicInfo.intro"></el-input>
                  </el-form-item>
                  <el-form-item label="营业执照" prop="businessLicense">
                    <el-upload
                      :show-file-list="false"
                      class="upload-demo"
                      drag
                      :before-upload="beforeAvatarUpload"
                      :http-request="updateBusinessLicense"
                      list-type="picture"
                      action=""
                      multiple>
                      <el-image
                        style="width: 100px; height: 100px"
                        :src="basicInfo.businessLicense"
                        fit="contain" v-if="basicInfo.businessLicense"></el-image>
                      <i class="el-icon-camera" v-else></i>
                      <div class="el-upload__text">请上传不大于2M的jpg/png格式的图</div>
                    </el-upload>
                  </el-form-item>
                  <el-form-item label="医疗许可证" prop="medicalLicense">
                    <el-upload
                      :show-file-list="false"
                      class="upload-medicalLicense"
                      drag
                      :before-upload="beforeAvatarUpload"
                      :http-request="updateMedicalLicense"
                      list-type="picture"
                      :file-list="basicInfo.medicalLicense? [{ name: 'medicalLicense', url: basicInfo.medicalLicense}] : []"
                      action=""
                      multiple>
                      <el-image
                        style="width: 100px; height: 100px"
                        :src="basicInfo.medicalLicense"
                        fit="contain" v-if="basicInfo.medicalLicense"></el-image>
                      <i class="el-icon-camera" v-else></i>
                      <div class="el-upload__text">请上传不大于2M的jpg/png格式的图</div>
                    </el-upload>
                  </el-form-item>

                  <el-form-item label="官方网址">
                    <el-input v-model="basicInfo.officialWebsite"></el-input>
                  </el-form-item>
                  <el-form-item label="地区">
                    <el-cascader
                      size="large"
                      :options="options"
                      v-model="selectedOptions"
                      @change="handleChange"
                      style="width: 100%"
                    >
                    </el-cascader>
                  </el-form-item>
                  <el-form-item label="地址">
                    <el-input v-model="basicInfo.location"></el-input>
                  </el-form-item>
                  <el-form-item label="维度">
                    <el-input v-model="basicInfo.longitude"></el-input>
                  </el-form-item>
                  <el-form-item label="经度">
                    <el-input v-model="basicInfo.latitude"></el-input>
                  </el-form-item>
                  <FormButtonGroup @saveData="onSubmit" save-text="提交"></FormButtonGroup>

                </el-form>
                <div class="ke3" id='ke3'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="账号密码"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <span>{{tips}}</span>
      <span slot="footer" class="dialog-footer">
                  <el-button @click="handleClose">关 闭</el-button>
                  <el-button type="primary" v-clipboard:copy="tips" v-clipboard:success="onCopy" v-clipboard:error="onError">复制账号密码</el-button>
                </span>
    </el-dialog>
  </div>
</template>
<script>

import ks3UrlService from '@/globals/service/management/Ks3Url.js'
import AdministratorService from '@/globals/service/management/Administrator'
import { provinceAndCityData } from 'element-china-area-data'
import OSS from 'ali-oss'

export default {
  data () {
    return {
      dialogVisible: false,
      tips: '',
      options: provinceAndCityData,
      selectedOptions: [],
      basicInfo: {
        dialogVisible: true,
        name: '',
        longitude: '',
        latitude: '',
        location: '',
        phone: '',
        provinceCode: '',
        cityCode: '',
        officialWebsite: '',
        intro: '',
        detailImg: '',
        businessLicense: '',
        medicalLicense: ''
      },
      basicInfoRules: {
        name: [
          {
            required: true,
            message: '请输入名称',
            trigger: 'blur'
          }
        ],
        phone: [
          {
            required: true,
            message: '请输入手机号码',
            trigger: 'change'
          }
        ],
        businessLicense: [
          {
            required: true,
            message: '请选择营业执照',
            trigger: 'change'
          }
        ],
        medicalLicense: [
          {
            required: true,
            message: '请选择医疗许可证',
            trigger: 'change'
          }
        ],
        legalPersonCard: [
          {
            required: true,
            pattern: /^\d{15}|\d{}18$/,
            message: '请输入法人身份证号',
            trigger: 'blur'
          }
        ],
        intro: [
          {
            required: true,
            message: '请输入简介',
            trigger: 'blur'
          }
        ]
      },
      client: null,
      date: ''
    }
  },
  created () {
    if (this.$route.params.item !== undefined) {
      this.basicInfo = this.$route.params.item
      if(this.basicInfo.legalPersonCard){
        this.basicInfo.legalPersonCard = this.$aes.decrypt(this.basicInfo.legalPersonCard,"v3W1E6APT3s0g48k");
      }
    }
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    this.date = year + '-' + month + '-' + day
    this.getOssToken()
  },
  methods: {
    handleClose (done) {
      this.$confirm('确认关闭吗？请确认已经复制了账号密码')
        .then(_ => {
          this.dialogVisible = false
          this.$router.go(-1)
          done()
        })
        .catch(_ => {})
    },
    // 复制成功时的回调函数
    onCopy (e) {
      this.$message.success('内容已复制到剪切板！')
    },
    // 复制失败时的回调函数
    onError (e) {
      this.$message.error('抱歉，复制失败！')
    },
    handleChange (value) {
      console.log(value)
      this.basicInfo.provinceCode = value[0]
      this.basicInfo.cityCode = value[1]
    },
    getUrlParams (name, url) {
      console.log(url)
      const data = url.split('?')
      console.log(data)
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      const param = data[1].match(reg)
      return param && param[2]
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'

      const isLt2M = file.size / 1024 / 1024 < 2
      console.log(isPNG)
      if (!isJPG && !isPNG) {
        this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return (isJPG || isPNG) && isLt2M
    },
    // 获取临时token
    getOssToken () {
      ks3UrlService.getKs3Url().then(res => {
        this.client = new OSS({
          accessKeyId: res.data.accessKeyId,
          accessKeySecret: res.data.accessKeySecret,
          stsToken: res.data.stsToken,
          bucket: res.data.bucket,
          region: res.data.region
        })
      })
    },
    // 上传图片
    async putObject (data) {
      try {
        const timestamp = Date.parse(new Date())
        const prefix = process.env.NODE_ENV === 'development' ? 'test' : 'release'
        const name = prefix + '/image/' + this.date + '/' + timestamp + (Math.ceil(Math.random() * 10000))
        return await this.client.put(name, data)
      } catch (e) {
        console.log(e)
      }
    },
    updateMedicalLicense (option) {
      this.putObject(option.file).then(res => {
        console.log('res', res)
        this.basicInfo.medicalLicense = res.url
      })
    },
    updateBusinessLicense (option) {
      this.putObject(option.file).then(res => {
        console.log('res', res)
        this.basicInfo.businessLicense = res.url
      })
    },
    // 取消返回
    handleCancel () {
      this.$router.go(-1)
    },
    copy (tips) {
      const that = this
      this.$copyText(tips).then(function (e) {
        console.log('e', e)
        that.$message({
          message: '复制成功',
          type: 'success'
        })
      }, function (e) {
        that.$message({
          message: '复制失败，请手工复制',
          type: 'error'
        })
      })
    },
    // 提交表单
    onSubmit () {
      this.$refs.organizationForm.validate(async (valid) => {
        if (valid) {
          // eslint-disable-next-line no-unused-vars
          let res = null
          const id = this.basicInfo.id
          if (id) {
            res = await AdministratorService.AdministratorUpdateHospital(this.basicInfo)
          } else {
            res = await AdministratorService.AdministratorAddHospital(this.basicInfo)
          }
          console.log('res', res)
          if (res.code === 1) {
            if (id) {
              this.$message.success('修改成功')
              this.$router.go(-1)
            } else {
              this.tips = `账号:${res.data.phone}; 密码:${res.data.password}`
              this.dialogVisible = true
            }
          } else {
            this.$message.error(res.errMsg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  min-height: 100%;
  flex: 1;
  background-color: #fff;
  color: #333;

  .body-container {
    padding: 20px 40px 20px 61px;

    .body-section {
      min-width: 450px;
    }

    .basic-container {
      max-width: 450px;

      .el-select {
        width: 100%;
      }
    }

    .all-container {
      .all-section-header {
        width: 100%;
        min-width: 160px;
        height: 50px;
        background-color: #F8F8F8;
        padding: 0 30px;
        border-radius: 6px;
        margin-bottom: 40px;

        .header-title {
          line-height: 50px;
          font-size: 18px;
          color: #333;
        }
      }

      .organization-from_section {
        max-width: 450px;

        /deep/ .el-icon-camera {
          font-size: 50px;
          color: #34BBEC;
          margin-top: 50px;

        }

        /deep/ .el-upload-dragger {
          width: 350px;
        }

        .button-section {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-top: 21px;
          padding-left: 20px;

          .add-button, .return-button {
            width: 120px;
            height: 40px;
            margin: 0 20px;
            border-radius: 20px;
            color: #fff;
            font-size: 16px;
          }

          .add-button {
            background-color: #34BBEC;
          }

          .return-button {
            background-color: #e4e4e4;
          }
        }
      }

      .government-from_section {
        max-width: 450px;

        .el-select {
          width: 100%;
        }

        .button-section {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-top: 21px;
          padding-left: 20px;

          .add-button, .return-button {
            width: 120px;
            height: 40px;
            margin: 0 20px;
            border-radius: 20px;
            color: #fff;
            font-size: 16px;
          }

          .add-button {
            background-color: #34BBEC;
          }

          .return-button {
            background-color: #e4e4e4;
          }
        }
      }

      .school-from_section {
        max-width: 450px;

        .button-section {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-top: 21px;
          padding-left: 20px;

          .add-button, .return-button {
            width: 120px;
            height: 40px;
            margin: 0 20px;
            border-radius: 20px;
            color: #fff;
            font-size: 16px;
          }

          .add-button {
            background-color: #34BBEC;
          }

          .return-button {
            background-color: #e4e4e4;
          }
        }
      }

      .class-from_section {
        max-width: 450px;

        .el-select, .el-autocomplete {
          width: 100%;
        }

        .button-section {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-top: 21px;
          padding-left: 20px;

          .add-button, .return-button {
            width: 120px;
            height: 40px;
            margin: 0 20px;
            border-radius: 20px;
            color: #fff;
            font-size: 16px;
          }

          .add-button {
            background-color: #34BBEC;
          }

          .return-button {
            background-color: #e4e4e4;
          }
        }
      }
    }
  }
}

/deep/ .el-popper, .el-select-dropdown {
  .hover {
    background-color: #34BBEC !important;
    color: #fff !important;
  }
}
</style>
/* eslint-disable */
