import axios from './../../request/axios.js'
import api from './../../request/api.js'
import DataStore from '@/globals/storage'

const ks3UrlService = {
  getKs3Url: function (params) {
    const loginKey = DataStore.get('loginKey')
    const uid = DataStore.get('uid')
    return axios.get(api.getKs3Url, {
      loginKey,
      uid
    })
  }
}
export default ks3UrlService
